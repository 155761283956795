import ContactForm from '../../Sections/ContactForm/ContactForm';
import $ from 'jquery';

export default {

	init() {

		var body = document.body;
		var container = document.querySelector('main.Calculator');

		if( container ) {

			var variable = []; // basicVariable

			function getBasicVariable() {

				let request = new XMLHttpRequest();
				let response;

				request.onreadystatechange = function() {

					if( request.readyState == 4 && request.status == 200 ) {

						response = JSON.parse(request.responseText);

						if( Object.keys(response).length > 0 ) {

							variable = response;

							// buttonHome.classList.remove('disable');
							// buttonCompany.classList.remove('disable');
							buttonNextStep2.classList.remove('disable');
							stepCalc2( container.querySelector('.Calculator__step--2 input[name="month_cost"][type="number"]').value );
							
							if( body.classList.contains('logged-in') ) {

								console.log(variable);

							}
							
						}

					}

				}

				// Set up our request
				request.open('GET', window.location.origin+'/wp-content/themes/rawicom/src/Pages/Calculator/CalculatorGetVariable.php?page_id='+container.getAttribute('data-id'), true );

				// Add the required HTTP header for form data POST requests
				request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');

				// Finally, send our data.
				request.send();

			}

			ContactForm.init();
			container.style.height = container.querySelector('.Calculator__step.active').offsetHeight + 'px';

			// -- współczynniki -- //

				var panelMinCost = 0;
				if( container.querySelector('.Calculator__step--5') )  {

					container.querySelectorAll('.Calculator__step--5 .package__single').forEach( function(single,index){

						if( panelMinCost === 0 ) {

							panelMinCost = parseFloat( single.getAttribute('data-price') );

						} else if( panelMinCost > parseFloat( single.getAttribute('data-price') ) ) {

							panelMinCost = parseFloat( single.getAttribute('data-price') );						

						}

					});

				}

			// -- END -- //



			var calculatorVariable = [];
			var messageVariable = [];
			var currentStep = 0; // index start from 0
			


			// -- next, prev slide, resize -- //

				var steps = container.querySelectorAll('.Calculator__step');

				var buttonNext = container.querySelectorAll('.buttonNext');
				var buttonPrev = container.querySelectorAll('.buttonPrev');

				buttonNext.forEach( function( button, index ) {

					button.addEventListener('click', e => {

						if( !button.classList.contains('disable') ) {

							currentStep += 1;

							while( steps[currentStep] && steps[currentStep].classList.contains('disable') ) {

								currentStep += 1;

							}

							if( steps[currentStep].classList.contains('Calculator__step--form') ) {

								loadContactFormData();

							}

							steps[currentStep].classList.add('active');
							container.style.height = steps[currentStep].offsetHeight + 'px';
							$('html ,body').animate({'scrollTop': 0 }, 'smooth');

						}

					});

				});

				buttonPrev.forEach( function( button, index ) {

					button.addEventListener('click', e => {

						steps[currentStep].classList.remove('active');

						currentStep -= 1;

						while( steps[currentStep] && steps[currentStep].classList.contains('disable') ) {
							currentStep -= 1;
						}

						container.style.height = steps[currentStep].offsetHeight + 'px';
						$('html ,body').animate({'scrollTop': 0 }, 'smooth');
						
					});					

				});

				function resizeContent() {

					container.style.height = container.querySelectorAll('.Calculator__step.active')[container.querySelectorAll('.Calculator__step.active').length-1].offsetHeight + 'px';

				}

				window.addEventListener('resize', e => {

					resizeContent();

				});

			// -- END -- //



			// -- step1 -- //

				var vat = 0; // home - 8%, company - 23%
				vat = 1.23;
				messageVariable.place = "Firmy";
				messageVariable.vat = vat;

				// var step1 = container.querySelector('.Calculator__step--1');

				// var buttonHome = step1.querySelector('.button__home');
				// var buttonCompany = step1.querySelector('.button__company');

				// buttonHome.addEventListener('click', e => {

				// 	if( !buttonHome.classList.contains('disable') ) {

				// 		vat = 1.08;
				// 		messageVariable.place = "Domu";
				// 		messageVariable.vat = vat;
				// 		stepCalc2( container.querySelector('.Calculator__step--2 input[name="month_cost"][type="number"]').value );

				// 	}

				// });

				// buttonCompany.addEventListener('click', e => {

				// 	if( !buttonCompany.classList.contains('disable') ) {

				// 		vat = 1.23;
				// 		messageVariable.place = "Firmy";
				// 		messageVariable.vat = vat;
				// 		stepCalc2( container.querySelector('.Calculator__step--2 input[name="month_cost"][type="number"]').value );

				// 	}

				// });

			// -- END -- //



			// -- step 2 -- //

				var step2 = container.querySelector('.Calculator__step--2');

				var monthCostRangeFake = step2.querySelector('input[name="month_cost_color"][type="range"]');
				var monthCostInput = step2.querySelector('input[name="month_cost"][type="number"]');
				var monthCostRange = step2.querySelector('input[name="month_cost"][type="range"]');
				var paidEveryRadio = step2.querySelectorAll('input[name="paid_every"]');

				var timeoutStep2 = null;
				var buttonNextStep2 = step2.querySelector('.Button.buttonNext');

				function getDiscount() {

					let discount = 0;

					if( calculatorVariable.modules && variable.discount ) {

						variable.discount.forEach( function( row,index ) {

							if( calculatorVariable.modules >= parseInt(row.from) && calculatorVariable.modules <= parseInt(row.to) ) {

								discount = parseFloat( row.percent ) / 100;

							}

						});

					}

					return discount;

				}

				function stepCalc2( value ) {

					calculatorVariable = [];

					// zużycie okresowe
					calculatorVariable.partConsumption = value / variable.averagePrice;
					// console.log('Twoje miesięczne zużycie energii: '+calculatorVariable.partConsumption);

					// zużycie roczne
					calculatorVariable.yearConsumption = calculatorVariable.partConsumption * (12/step2.querySelector('.grid__cell input:checked').value);
					// console.log('Twoje roczne zużycie energii (kWh): '+ calculatorVariable.yearConsumption);

					// autokomsumpcja
					calculatorVariable.instalationConsumptions = calculatorVariable.yearConsumption * 0.25;
					// console.log('autokonsumpcja 25%: ' + calculatorVariable.instalationConsumptions);

					// wyliczony wspolczynnik w zaleznosci od kwp
					if( value > 1000 ) {

						calculatorVariable.calculateKWP = variable.kwpUpper10;

					} else {

						calculatorVariable.calculateKWP = variable.kwpLower10;

					}
					// console.log('wyliczony wspolczynnik w zaleznosci od kwp: '+ calculatorVariable.calculateKWP );

					// zapotrzebowanie energi z sieci
					calculatorVariable.energyRequired = (calculatorVariable.yearConsumption - calculatorVariable.instalationConsumptions) / calculatorVariable.calculateKWP;
					// console.log('zapotrzebowanie energi z sieci:' + calculatorVariable.energyRequired);

					// sugerowana wielkosc instalacji [kWp]
					calculatorVariable.suggestedPower = (calculatorVariable.energyRequired + calculatorVariable.instalationConsumptions) / variable.instalationPerformance;
					// console.log('sugerowana wielkosc instalacji [kWp]:'+calculatorVariable.suggestedPower);

					// ilość modułów
					calculatorVariable.modules = Math.round( (calculatorVariable.suggestedPower * 1000 )/ variable.panelPower); 
					// console.log('ilość modułów:'+calculatorVariable.modules);

					// zniżka
					calculatorVariable.discount = getDiscount();


					// moc instalacji (W)
					calculatorVariable.instalationPower = calculatorVariable.modules*variable.panelPower;
					calculatorVariable.instalationPower = Math.round( calculatorVariable.instalationPower * 100 )/ 100;
					// console.log('moc instalacji:'+calculatorVariable.instalationPower);


					// powierzchnia instalacji
					calculatorVariable.instalationSurface = Math.round( (calculatorVariable.modules* variable.panelSurface)*100 )/ 100;
					// console.log('Powierzchnia instalacji: '+ calculatorVariable.instalationSurface);


					// koszt instalacji
					calculatorVariable.costTotal = Math.round( calculatorVariable.modules * vat * ( panelMinCost - ( panelMinCost * calculatorVariable.discount ) ) * 100 ) / 100;
					// console.log('Koszt instalacji: '+ calculatorVariable.costTotal);

					// miesięczna rata
					calculatorVariable.monthRate = ( ( variable.ratesMonth * variable.interest * calculatorVariable.costTotal) + calculatorVariable.costTotal) / variable.ratesMonth;
					calculatorVariable.monthRate = Math.round( calculatorVariable.monthRate * 100 ) / 100
					// console.log('Miesięczna rata: '+ calculatorVariable.monthRate);


					// -- change layout before format -- //

						if( calculatorVariable.modules > 108 || Math.round( calculatorVariable.instalationPower/10 )/ 100 > 50 ) {

							body.classList.add('Calculator--disableCost');

							if( container.querySelector('.Calculator__step--5') ) {

								container.querySelector('.Calculator__step--5').classList.add('disable');

							}

						} else {

							body.classList.remove('Calculator--disableCost');

							if( container.querySelector('.Calculator__step--5') ) {

								container.querySelector('.Calculator__step--5').classList.remove('disable');

							}

						}

					// -- END -- //


					// -- round values before show -- //

						calculatorVariable.instalationPower = (calculatorVariable.instalationPower).toFixed(2);
						if( calculatorVariable.instalationPower > 1000 ) {

							calculatorVariable.instalationPower =  Math.round( calculatorVariable.instalationPower/10 )/ 100 +' kW';

						} else {

							calculatorVariable.instalationPower = calculatorVariable.instalationPower + ' W';

						}

						calculatorVariable.suggestedPower = (calculatorVariable.suggestedPower ).toFixed(2);
						calculatorVariable.monthRate = (calculatorVariable.monthRate).toFixed(2);
						calculatorVariable.instalationSurface = (calculatorVariable.instalationSurface).toFixed(2);
						calculatorVariable.costTotal = (calculatorVariable.costTotal).toFixed(2);
						calculatorVariable.modules = calculatorVariable.modules;

					// -- END -- //

					stepsLoadData();

					// message variable -- //

						messageVariable.partCost = value;
						messageVariable.partTime = step2.querySelector('.grid__cell input:checked').getAttribute('data-label');
						messageVariable.instalationPower = calculatorVariable.instalationPower;
						messageVariable.panelPower = variable.panelPower;
						messageVariable.modules = calculatorVariable.modules;
						messageVariable.instalationSurface = calculatorVariable.instalationSurface;

					// -- END -- //

				}

				var fakeVal = 0;
				function calcFakeRangeValue( input ) {

					monthCostRangeFake.value = input.value;

					fakeVal = (input.value - parseFloat(input.min) ) / ( parseFloat(input.max) - parseFloat(input.min) )

					monthCostRangeFake.style.backgroundImage = '-webkit-gradient(linear, left top, right top, '
		                + 'color-stop(' + fakeVal + ', #C40003), '
		                + 'color-stop(' + fakeVal + ', transparent)'
		                + ')';

				}


				monthCostRange.addEventListener('input', e => {

					buttonNextStep2.classList.add('disable');
					monthCostInput.value = monthCostRange.value;

					clearTimeout(timeoutStep2);
					timeoutStep2 = null;

					timeoutStep2 = setTimeout(
						function() {

				 			stepCalc2( monthCostInput.value );

				 			if( buttonNextStep2.classList.contains('disable') ) {

								buttonNextStep2.classList.remove('disable');

							}

		 				}, 
						500
					);

					calcFakeRangeValue( monthCostRange );

				});

				monthCostInput.addEventListener('input', e => {

					buttonNextStep2.classList.add('disable');
					monthCostRange.value = monthCostInput.value;

					clearTimeout(timeoutStep2);
					timeoutStep2 = null;

					timeoutStep2 = setTimeout(
						function() {

							if( monthCostInput.value ) {

								if( monthCostInput.value > parseInt(monthCostInput.max) ) {

									monthCostInput.value = parseInt(monthCostInput.max);

								}

								if( monthCostInput.value < parseInt(monthCostInput.min) ) {

									monthCostInput.value = parseInt(monthCostInput.min);

								}

					 			stepCalc2( monthCostInput.value );

					 			if( buttonNextStep2.classList.contains('disable') ) {

									buttonNextStep2.classList.remove('disable');

								}

							} else {

								stepCalc2( 0 );

							}

		 				}, 
						500
					);

					calcFakeRangeValue( monthCostRange );

				});

				paidEveryRadio.forEach( function( radio,index ) {

					radio.addEventListener('change', e => {

						stepCalc2( monthCostInput.value );

					});

				});

			// -- END -- //



			// -- step 3 -- //

				var step3 = container.querySelector('.Calculator__step--3');
				var buttonNextStep3 = step3.querySelectorAll('.buttonNext');
				var step3Radio = step3.querySelectorAll('input[name="roof_type"]');

				step3Radio.forEach( function( input,index ) {

					input.addEventListener('change', function() {

						calculatorVariable.roofType = input.value;
						messageVariable.roofType = input.value;

						if( input.value == "Grunt" ) {

							container.querySelector('.Calculator__step--4').classList.add('disable');
							container.querySelector('.Calculator__step--4 input[value="Inny"]').checked = true;
							calculatorVariable.roofCovering = "Inny";
							messageVariable.roofCovering = "Inny";

						} else {

							container.querySelector('.Calculator__step--4').classList.remove('disable');
							container.querySelector('.Calculator__step--4 input[value="Inny"]').checked = false;
							calculatorVariable.roofCovering = "";
							messageVariable.roofCovering = "";

						}

						buttonNextStep3.forEach( function( button,index ) {

							button.classList.remove('disable');

						});

					});

				});

			// -- END -- //



			// -- step4 -- //

				var step4 = container.querySelector('.Calculator__step--4');

				var buttonNextStep4 = step4.querySelectorAll('.buttonNext');

				var step4Radio = step4.querySelectorAll('input[name="roof_covering"]');

				step4Radio.forEach( function( input,index ) {

					input.addEventListener('change', e => {

						calculatorVariable.roofCovering = input.value;

						messageVariable.roofCovering = input.value;

						buttonNextStep4.forEach( function( button,index ) {

							button.classList.remove('disable');

						});

					});

				});

			// -- END -- //



			// -- step5 -- //

				var step5 = container.querySelector('.Calculator__step--5');
				if( step5 ) {

					var step5Radio = step5.querySelectorAll('input[name="package_choice"]');

					step5Radio.forEach( function( input,index ) {

						input.addEventListener('change', function() {

							step5.querySelectorAll('.buttonNext').forEach( function( button,index ) {

								button.classList.remove('disable');

							});

						});

					});

				}

			// -- END -- //


			container.querySelector('.form__submit').addEventListener('click', e => {

				setTimeout( 
					function(){
						resizeContent();
					},
					300,
				);					

			});
			

			// -- fill data -- //

				function stepsLoadData() {


					step2.querySelector('.step__aside .instalationPower .aside__value').textContent = calculatorVariable.instalationPower;


					step3.querySelector('.step__aside .instalationPower .aside__value').textContent = calculatorVariable.instalationPower;
					step3.querySelector('.step__aside .panelPower .aside__value').textContent =  variable.panelPower+ ' ';
					step3.querySelector('.step__aside .modules .aside__value').textContent = Math.round(calculatorVariable.modules);
					step3.querySelector('.step__aside .instalationSurface .aside__value').textContent = calculatorVariable.instalationSurface+" ";


					step4.querySelector('.step__aside .instalationPower .aside__value').textContent = calculatorVariable.instalationPower;
					step4.querySelector('.step__aside .panelPower .aside__value').textContent = variable.panelPower+ ' ';
					step4.querySelector('.step__aside .modules .aside__value').textContent = Math.round(calculatorVariable.modules);
					step4.querySelector('.step__aside .instalationSurface .aside__value').textContent = calculatorVariable.instalationSurface+" ";

					if( !body.classList.contains('Calculator--disableCost') ) {
						step4.querySelector('.step__aside .instalationCost .aside__value').textContent = ' '+calculatorVariable.costTotal+' ';
						step4.querySelector('.step__aside .monthCost .aside__value').textContent = ' '+calculatorVariable.monthRate+' ';
					} else {
						step4.querySelector('.step__aside .instalationCost .aside__value').textContent = '';
						step4.querySelector('.step__aside .monthCost .aside__value').textContent = '';
					}

					if( step5 ) {

						step5.querySelector('.step__aside .instalationPower .aside__value').textContent = calculatorVariable.instalationPower;
						step5.querySelector('.step__aside .panelPower .aside__value').textContent = variable.panelPower+ ' ';
						step5.querySelector('.step__aside .modules .aside__value').textContent = Math.round(calculatorVariable.modules);
						step5.querySelector('.step__aside .instalationSurface .aside__value').textContent = calculatorVariable.instalationSurface+" ";

						if( !body.classList.contains('Calculator--disableCost') ) {
							step5.querySelector('.step__aside .instalationCost .aside__value').textContent = ' '+calculatorVariable.costTotal+' ';
							step5.querySelector('.step__aside .monthCost .aside__value').textContent = ' '+calculatorVariable.monthRate+' ';
						} else{
							step5.querySelector('.step__aside .instalationCost .aside__value').textContent = '';
							step5.querySelector('.step__aside .monthCost .aside__value').textContent = '';
						}


						step5.querySelectorAll('.package__single').forEach( function(single,index) {

							single.querySelector('.package__cost').textContent = 
								( 
									(
										parseFloat( single.getAttribute('data-price') ) - 
										( 
											(
												parseFloat( single.getAttribute('data-price') ) * 
												calculatorVariable.discount 
											) 
										)
									) * 
									calculatorVariable.modules*vat
								).toFixed(2) +" ";

						});

					}

				}

				var contactForm = container.querySelector('section.ContactForm--calculator');
				function loadContactFormData() {

					contactForm.querySelector('input[name="instalation_for"]').value = messageVariable.place;
					contactForm.querySelector('input[name="energy_cost"]').value = messageVariable.partCost;
					contactForm.querySelector('input[name="energy_cost_per"]').value = messageVariable.partTime;
					contactForm.querySelector('input[name="roof_type"]').value = messageVariable.roofType;
					contactForm.querySelector('input[name="roof_covering"]').value = messageVariable.roofCovering;
					contactForm.querySelector('input[name="instalation_power"]').value = messageVariable.instalationPower;
					contactForm.querySelector('input[name="panel_power"]').value = messageVariable.panelPower;
					contactForm.querySelector('input[name="modules"]').value = messageVariable.modules;
					contactForm.querySelector('input[name="instalation_surface"]').value = messageVariable.instalationSurface;
					contactForm.querySelector('input[name="vat"]').value = messageVariable.vat;
					contactForm.querySelector('input[name="discount"]').value = calculatorVariable.discount;

					if( body.classList.contains('Calculator--disableCost') ) {
						contactForm.querySelector('input[name="contact_customer"]').value = 0;
					} else{
						contactForm.querySelector('input[name="contact_customer"]').value = 1;
					}

				}

			// -- END -- //

			calcFakeRangeValue( monthCostRange );
			getBasicVariable();

		}

	},
	finalize() {

	}

}