import ContactForm from '../../Sections/ContactForm/ContactForm';

export default {

	init() {

		ContactForm.init();

	},
	finalize() {

	}

}