import LazyLoad from '../../../common/scripts/lazyLoad';
import Subpage from '../../Layout/Subpage';

export default {

	init() {

		var container = document.querySelector('main.ArchiveBlog');

		if( container ) {

			// -- get more realization -- //

				function loadMore() {

					var moreButton;
					var xhr = new XMLHttpRequest();
					var xmlDoc = null;
					var basicUrl = window.location.origin + window.location.pathname ;
					var maxPosts = parseInt( container.getAttribute('data-max') );

					document.addEventListener('click', function(e) {

						if( e.target.classList.contains('tiles__more') ) {

							moreButton = document.querySelector('.tiles__more');

							xhr = new XMLHttpRequest();
							xhr.onreadystatechange = function() {

								if( xhr.readyState == 4 && xhr.status == 200) {

			            			var xmlDoc = document.implementation.createHTMLDocument("example");
									xmlDoc.documentElement.innerHTML = xhr.responseText;

									xmlDoc.querySelectorAll('.tiles--size .BlogTile').forEach( function( box,index ) {

										container.querySelector('.tiles--size').insertBefore(box, container.querySelector('.BlogTile').lastChild.nextSibling );

									});

									maxPosts = xmlDoc.querySelector('main').getAttribute('data-max');
									container.setAttribute( 'data-offset', container.querySelectorAll('.BlogTile').length );


									xmlDoc.querySelectorAll('.tiles--size .BlogMore').forEach( function( box,index ) {

										container.querySelector('.tiles--size').insertBefore(box, container.querySelector('.BlogMore').lastChild.nextSibling );

									});

									moreButton.parentNode.remove();

									LazyLoad.init();

									Subpage.slideUp();

			        			} 

							}
							xhr.open( 'GET', basicUrl+'?offset='+container.getAttribute('data-offset')+'&ajax=yes', true );
							xhr.send();

						}

					});

				}

				loadMore();

			// -- END -- //


		}

	},
	finalize() {

	}

}