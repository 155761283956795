export default {

	init() {

		var container = document.querySelector('footer form.Newsletter');

		if( container ) {

			var form = container;
			var field = form.querySelector('input[name="email"]');
			var buttonSubmit = form.querySelector('button');

			// -- Form submit -- //

				var formSubmit = false;
								
				var fieldErrorEmail = form.querySelector('.form-errorEmail');
				var fieldEmptyEmail = form.querySelector('.form-emptyEmail');
				var fieldErrorCheckbox = form.querySelector('.form-error-checkbox');
				var fieldError = form.querySelector('.form-error');
				var fieldSuccess = form.querySelector('.form-success');

				var response;
				var request = new XMLHttpRequest();
				var requestData = [];

				buttonSubmit.addEventListener('click', function( e ) {
					
					e.preventDefault();
					fieldErrorEmail.classList.remove('active');
					fieldEmptyEmail.classList.remove('active');
					fieldErrorCheckbox.classList.remove('active');

					if( !buttonSubmit.classList.contains('disable') && !formSubmit ) {

						buttonSubmit.classList.add('disable');

						let checked = true;

						form.querySelectorAll('input[type="checkbox"]').forEach( function( input, index ) {

							if( !input.checked ) {

								checked = false;

							}

						});

						if( !field.value ) {

							fieldEmptyEmail.classList.add('active');
							buttonSubmit.classList.remove('disable');

						} else if( !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test( field.value ) ) {

							fieldErrorEmail.classList.add('active');
							buttonSubmit.classList.remove('disable');

						} else if( !checked ) {

							fieldErrorCheckbox.classList.add('active');
							buttonSubmit.classList.remove('disable');

						} else {

							requestData = [];
							requestData.push('action=newsletter');
							requestData.push('email='+field.value);
							requestData.push('lang='+ form.querySelector('input[name="lang"]').value );
							requestData.push('security=' + container.querySelector('input#authorizeNewsletterComponent').value );

							requestData = requestData.join('&');

							request.onreadystatechange = function() {

								if( request.responseText ) {

									response = JSON.parse( request.responseText );
									
									if( response.status == 'success' ) {
										
										fieldSuccess.classList.add('active');
										formSubmit = true;

									} else if( response.status == 'error' ) {

										fieldError.classList.add('active');
										buttonSubmit.classList.remove('disable');

									}

								}

							}

							// Set up our request
							request.open('POST', document.querySelector('footer').getAttribute('data-endpoint') );
							// Add the required HTTP header for form data POST requests
							request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
							// Finally, send our data.
							request.send( requestData );

						}

					}

				});

				form.addEventListener('submit', function( e ) {

					e.preventDefault();			

				});

			// -- END -- //


			// -- Field email input -- //

				field.addEventListener('input', function() {

					if( field.value ) {

						buttonSubmit.classList.add('active');

					} else {

						buttonSubmit.classList.remove('active');

					}

				});

			// -- END -- //

		}

	},
	finalize() {

	}

}