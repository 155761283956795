import './Photoswipe.scss';
import PhotoSwipe from 'photoswipe';
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default';

class Photoswipe {

    constructor(node) {
        this.node = node;
    }
    init( index = null, images ) {

    	if( this.node && index != null  && images ) {

    		let items = Array();
	    	let options = Array(); // photoSwipe options
				options.zoomEl = true;
				options.fullscreenEl = false;
				options.shareEl = false;
				options.index = parseInt(index, 10);
			let photoSwipe; // photoSwipe object

			images.forEach( function( image,index ) {
				items.push({ 
					src: image.getAttribute('data-url'),
					w: image.getAttribute('data-width'),
					h: image.getAttribute('data-height'),
				});
			});
			photoSwipe = new PhotoSwipe( this.node.querySelector('.pswp'), PhotoSwipeUI_Default, items, options );
			photoSwipe.init();

    	}  	

    }

}
export default Photoswipe;