import slick from 'slick-carousel';
import $ from 'jquery';
import RecommendationsSlider from '../../Sections/RecommendationsSlider/RecommendationsSlider';
import ContactForm from '../../Sections/ContactForm/ContactForm';

export default {

	init() {

		var container = document.querySelector('main.PhotovoltaicForHome');

		if( container ) {

			RecommendationsSlider.init();
			ContactForm.init();

			// -- elastic section carousel -- //

				var carousels = container.querySelectorAll('.photovoltaicForHome__carousel');
				var header = document.querySelector('header');


				function renderCarousel( carousel ) {

					var carouselNawigaction = carousel.querySelector('.carousel__nawigation');
					var carouselNawigactionButton = carouselNawigaction.querySelectorAll('.nawigation__button');

					var carouselContent = carousel.querySelector('.carousel__content');
					var carouselImages = carousel.querySelector('.carousel__images');

					var carouselTopContainer = carousel.querySelector('.carousel__size');

					$(carouselNawigaction).slick({
						slidesToShow: 6,
						slidesToScroll: 1,
						rows: false,
						arrows: false,
						infinite: false,
						responsive: [
							{
								breakpoint: 1421,
								settings: {
									slidesToShow: 5,
								}
							},
							{
								breakpoint: 1161,
								settings: {
									slidesToShow: 4,
								}
							},
							{
								breakpoint: 861,
								settings: {
									slidesToShow: 3,
								}
							},
							{
								breakpoint: 621,
								settings: {
									slidesToShow: 2,
								}
							},
							{
								breakpoint: 391,
								settings: {
									slidesToShow: 1.5,
								}
							}
						]
					});

					$(carouselContent).slick({
						slidesToShow:1,
						sliderToScroll:1,
						arrows: false,
						row: false,
						fade: true,
  						cssEase: 'linear',
  						draggable: false,
  						infinite: false,
  						adaptiveHeight: true
					});

					$(carouselImages).slick({
						slidesToScroll: 1,
						slidesToShow: 1,
						arrows: false,
						rows: false,
						variableWidth: true,
						infinite: false,
					});

					$(carouselNawigaction).on('beforeChange', function(event, slick, currentSlide, nextSlide) {

						carouselNawigactionButton.forEach( function( button,index )  {

							button.classList.add('disable');

						});	


					});

					$(carouselNawigaction).on('afterChange', function(event, slick, currentSlide) {

						carouselNawigactionButton.forEach( function( button,index )  {

							button.classList.remove('disable');

						});	

					});

					$(carouselImages).on('afterChange', function(event, slick, currentSlide) {

						$(carouselNawigaction).slick('slickGoTo', currentSlide);
						$(carouselContent).slick('slickGoTo', currentSlide);
						
					});

					carouselNawigactionButton.forEach( function(button, index) {

						button.addEventListener('click', function() {

							$(carouselNawigaction).slick('slickGoTo', index);
							$(carouselContent).slick('slickGoTo', index);
							$(carouselImages).slick('slickGoTo', index);
							$('html ,body').animate({'scrollTop': (carouselNawigaction.offsetTop - header.offsetHeight) }, 'smooth');

						});

					});	

				}

				if( carousels.length > 0 ) {

					carousels.forEach( function( carousel, index ) {

						renderCarousel(carousel);

					});

				}

			// -- END -- //

		}

	},
	finalize() {

	}

}