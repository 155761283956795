// Defaults
import './style.scss';
import Router from './scripts/Router';
import LazyLoad from './scripts/lazyLoad';
import Subpage from '../src/Layout/Subpage';

// Pages
import homepage from '../src/Pages/Homepage/Homepage';
import singlerealization from '../src/Pages/SingleRealization/SingleRealization';
import photovoltaicforhome from '../src/Pages/PhotovoltaicForHome/PhotovoltaicForHome';
import archiverealization from '../src/Pages/ArchiveRealization/ArchiveRealization';
import archiveblog from '../src/Pages/ArchiveBlog/ArchiveBlog';
import contact from '../src/Pages/Contact/Contact';
import calculator from '../src/Pages/Calculator/Calculator';
import reference from '../src/Pages/Reference/Reference';

LazyLoad.init();
Subpage.init();
Subpage.slideUp();

const routes = new Router({
	homepage,
	singlerealization,
	photovoltaicforhome,
	archiverealization,
	archiveblog,
	contact,
	calculator,
	reference,
});

document.addEventListener("DOMContentLoaded", function() {
	
	routes.loadEvents();

});