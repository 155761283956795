export default {

	init() {

		var container = document.querySelector('section.ContactForm');
		var container1 = document.querySelector('section.ContactForm--simple');
		var container2 = document.querySelector('section.ContactForm--calculator');

		if( container ) {

			// -- label transform -- //

				function labelTransform() {

					var formFields = container.querySelectorAll('.form__field');

					formFields.forEach( function( input, index ) {

						if( input.type != 'checkbox' ) {

							input.addEventListener('focus', function() {

								input.closest('.form__box').classList.add('focus');

							});

							input.addEventListener('input', function() {

								if( input.value ) {

									input.closest('.form__box').classList.add('focus');

								} else {

									input.closest('.form__box').classList.remove('focus');

								}

							});

							input.addEventListener('blur', function() {

								if( !input.value ) {

									input.closest('.form__box').classList.remove('focus');

								}

							});

						}

					});

				}

				labelTransform();

			// -- END -- //

		}

		if( container1 ) {

			// -- send form -- //

				var form = container1.querySelector('form.contactForm__form');

				var formFields = form.querySelectorAll('.form__field');

				var callbackEmpty = container1.getAttribute('data-empty');
				var callbackEmailError = container1.getAttribute('data-emailError');
				var callbackFormError = container1.getAttribute('data-formError');
				var callbackFormSuccess = container1.getAttribute('data-formSuccess');

				var submitButton = form.querySelector('.form__submit');
				var formSubmit = false;

				var t = this;

				var request = new XMLHttpRequest();
				var response;
				var requestData = [];

				form.addEventListener('submit', function(e) {

					e.preventDefault();

					if( !formSubmit && !submitButton.classList.contains('disable') ) {

						submitButton.classList.add('disable');

						formFields.forEach( function( input, index ) {

							if( input.type == 'checkbox' ) {

								if( input.checked ) {

									input.classList.remove('empty');

								} else {

									input.classList.add('empty');

								}

							} else {

								if( input.value ) {

									input.classList.remove('empty');

								} else {

									input.classList.add('empty');

								}

								if( input.type == "email" ) {

									if( /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(input.value) ) {

										input.classList.remove('error');

									} else {

										input.classList.add('error');

									}

								}

							}

						});

						if( form.querySelectorAll('.form__field.empty').length > 0 ) {

							t.callback( callbackEmpty );
							submitButton.classList.remove('disable');

						} else if ( form.querySelectorAll('.form__field.error').length > 0 ) {

							t.callback( callbackEmailError );
							submitButton.classList.remove('disable');

						} else {

							// -- request data -- //

								requestData = [];

								requestData.push( 'action=sendMail' );
								requestData.push( 'security='+container1.querySelector('input#security').value );

								formFields.forEach( function( input,index ) {

									if( input.type != 'checkbox' ) {

										requestData.push( input.name+'='+input.value );									

									}

								});

								requestData = requestData.join('&');

							// -- END -- //

							// -- request -- //

								request = new XMLHttpRequest();

								request.onreadystatechange = function() {

									if( request.responseText && request.readyState == 4 && request.status == 200 ) {

										response = JSON.parse(request.responseText);

										if( response.status == 'success' ) {

											t.callback(callbackFormSuccess);
											formSubmit = true;

											t.sendToNewsletter( 
												container1.querySelector('input#authorizeNewsletterComponent').value, 
												container1.querySelector('input[name="email"]').value,
												container1.querySelector('input[name="lang"]').value,
												'formularz_kontaktowy'
											);

										} else if( response.status == 'error' ) {

											t.callback(callbackFormError);
											submitButton.classList.remove('disable');

										}

									}

								}

								// Set up our request
								request.open('POST', document.querySelector('footer').getAttribute('data-endpoint') );

								// Add the required HTTP header for form data POST requests
								request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');

								// Finally, send our data.
								request.send(requestData);

							// -- END -- //


						}

					}

				});

			// -- END -- // 

		}

		if( container2 ) {

			var form = container2.querySelector('form.contactForm__form');

			var formFields = form.querySelectorAll('.form__field');
			var extraFields = form.querySelectorAll('.calculator__field');

			var callbackEmpty = container2.getAttribute('data-empty');
			var callbackEmailError = container2.getAttribute('data-emailError');
			var callbackFormError = container2.getAttribute('data-formError');
			var callbackFormSuccess = container2.getAttribute('data-formSuccess');

			var submitButton = form.querySelector('.form__submit');
			var formSubmit = false;

			var t = this;

			var request = new XMLHttpRequest();
			var response;
			var requestData = [];

			form.addEventListener('submit', function(e) {

				e.preventDefault();

				if( !formSubmit && !submitButton.classList.contains('disable') ) {

					submitButton.classList.add('disable');

					formFields.forEach( function( input, index ) {

						if( input.type == 'checkbox' ) {

							if( input.checked ) {

								input.classList.remove('empty');

							} else {

								input.classList.add('empty');

							}

						} else {

							if( input.value ) {

								input.classList.remove('empty');

							} else {

								input.classList.add('empty');

							}

							if( input.type == "email" ) {

								if( /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(input.value) ) {

									input.classList.remove('error');

								} else {

									input.classList.add('error');

								}

							}

						}

					});

					if( form.querySelectorAll('.form__field.empty').length > 0 ) {

						t.callback( callbackEmpty );
						submitButton.classList.remove('disable');

					} else if ( form.querySelectorAll('.form__field.error').length > 0 ) {

						t.callback( callbackEmailError );
						submitButton.classList.remove('disable');

					} else {

						// -- request data -- //

							requestData = [];

							requestData.push( 'action=sendMailCalculator' );
							requestData.push( 'security='+container2.querySelector('input#security').value );

							formFields.forEach( function( input,index ) {

								if( input.type != 'checkbox' ) {

									requestData.push( input.name+'='+input.value );									

								}

							});

							extraFields.forEach( function(input,index) {

								requestData.push( input.name+'='+input.value );

							});

							requestData = requestData.join('&');

						// -- END -- //

						// -- request -- //

							request = new XMLHttpRequest();

							request.onreadystatechange = function() {

								if( request.responseText && request.readyState == 4 && request.status == 200 ) {

									response = JSON.parse(request.responseText);

									if( response.status == 'success' ) {

										document.querySelector('.buttonNext:not(.disable)').click();

										// t.callback(callbackFormSuccess);
										formSubmit = true;

										t.sendToNewsletter( 
											container2.querySelector('input#authorizeNewsletterComponent').value, 
											container2.querySelector('input[name="email"]').value,
											container2.querySelector('input[name="lang"]').value,
											'formularz_kalkulator'
										);

									} else if( response.status == 'error' ) {

										t.callback(callbackFormError);
										submitButton.classList.remove('disable');

									}

								}

							}

							// Set up our request
							request.open('POST', document.querySelector('footer').getAttribute('data-endpoint') );

							// Add the required HTTP header for form data POST requests
							request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');

							// Finally, send our data.
							request.send(requestData);

						// -- END -- //


					}

				}

			});

		}

	},
	finalize() {

	},
	callback( message ) {

		document.querySelectorAll('.form__callback').forEach( function( info, index ) {

			info.remove();

		});

		if( message )  {

			document.querySelector('section.ContactForm .form__width').insertAdjacentHTML('beforebegin','<div class="form__callback"><div><p>'+message+'</p></div></div>');

		}

	},
	sendToNewsletter( authorization, email, lang, tag ) {

		var response;
		var request = new XMLHttpRequest();
		var requestData = [];

		requestData = [];
		requestData.push('action=newsletter');
		requestData.push('email=' + email);
		requestData.push('lang='+ lang );
		requestData.push('security=' + authorization );
		requestData.push('tag=' + tag );

		requestData = requestData.join('&');

		request.onreadystatechange = function() {

			if( request.responseText ) {

				response = JSON.parse( request.responseText );
				// console.log( response );

			}

		}

		// Set up our request
		request.open('POST', document.querySelector('footer').getAttribute('data-endpoint') );
		// Add the required HTTP header for form data POST requests
		request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
		// Finally, send our data.
		request.send( requestData );

	}


}