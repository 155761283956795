import ScrollMagic from 'scrollmagic';
import RecommendationsSlider from '../../Sections/RecommendationsSlider/RecommendationsSlider';
import ContactForm from '../../Sections/ContactForm/ContactForm';
import PostsSection from '../../Sections/PostsSection/PostsSection';

export default {
	init() {
		
		var container = document.querySelector('main.Homepage');

		if( container ) {

    		RecommendationsSlider.init();
            ContactForm.init();
            PostsSection.init();

            // -- diagram counter -- //

            	var valueDiagrams = document.querySelectorAll('.homepage__diagrams .grid__cell--value .cell__diagram');

            	if( valueDiagrams.length > 0 ) {

            		var controller = new ScrollMagic.Controller();

            		valueDiagrams.forEach( function( diagram,index ) {

            			new ScrollMagic.Scene({triggerElement: diagram, triggerHook: 1 })
							.addTo(controller)
							.offset(0)
							.on('enter', function() {

								var interval;
								interval = setInterval( function() {

										if( parseFloat( diagram.querySelector('p').innerText) >= diagram.getAttribute('data-value') ) {

											diagram.querySelector('p').innerText = diagram.getAttribute('data-value');
											clearInterval(interval);
											interval = null;

										} else {

											diagram.querySelector('p').innerText = parseInt(diagram.querySelector('p').innerText) + parseInt(diagram.getAttribute('data-value')/100);

										}

									}, 
									4,
								);

							});

            		});

            	}

            // -- END -- //

    	}
    	
	},
	finalize() {
		// JavaScript to be fired on the home page, after the init JS
	},
};
