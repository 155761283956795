import ScrollMagic from 'scrollmagic';
import $ from 'jquery';
import Newsletter from '../Components/Newsletter/Newsletter';
import callpage from '../Components/Callpage/Callpage';

export default {

	init() {

		Newsletter.init();

		// -- mobile menu -- //

			var body = document.querySelector('body');

			var mobileMenu = body.querySelector('.MobileMenu');
			var mobileMenuContent = mobileMenu.querySelector('.mobileMenu__content');

			var hamburger = body.querySelector('header .right__hamburger');

			hamburger.addEventListener('click', function() {

				hamburger.classList.add('is-active');
				mobileMenu.classList.add('active');
				body.classList.add('disableScroll');

			});

			mobileMenu.addEventListener('click', function() {

				hamburger.classList.remove('is-active');
				mobileMenu.classList.remove('active');
				body.classList.remove('disableScroll');

			});	

			mobileMenuContent.addEventListener('click', function(e) {

				e.stopPropagation();

			});

		// -- END -- //


		// -- Header extra info action -- //

			var extraInfo = document.querySelector('header .header__info');

			if( extraInfo ) {

				setTimeout(function(){ $(extraInfo).addClass('active'); }, 3000);

				var extraInfoButton = extraInfo.querySelector('button');

				extraInfoButton.addEventListener('click', function(e) {

					e.preventDefault();
					e.stopPropagation();

			    	var d = new Date();
			    	d.setTime(d.getTime() + (24*60*60*1000) );
			    	var expires = "expires="+ d.toUTCString();
			    	document.cookie = "covid-info=1;" + expires + ";path=/";

			    	extraInfo.remove();

				});


			}

		// -- END -- //
		

		// -- callpage init -- //

			if(document.readyState === 'complete') { 

				setTimeout( 
					function() {
						callpage.init()
					}, 
					5000 
				); 

			} else {

	            document.addEventListener('readystatechange', function(e) {

	                if( e.target.readyState === 'complete' ) { 

		                setTimeout( 
							function() {
								callpage.init()
							}, 
							5000 
						); 
	                }

	            });

	        }

	    // -- END -- //

	},
	finalize() {

	},
	slideUp() {

		var elements = document.querySelectorAll('.slide-up');

		if( elements ) {

			var controller = new ScrollMagic.Controller();

			elements.forEach( function( element,index ) {

				new ScrollMagic.Scene({triggerElement: element, triggerHook: 1 })
					// trigger animation by adding a css class
					.setClassToggle(element, "slide-up--animation")
					// .addIndicators({name: "- Subpage "}) // add indicators (requires plugin)
					.addTo(controller)
					.offset(0);

			});

		}	

	}

}