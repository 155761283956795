import slick from 'slick-carousel';
import $ from 'jquery';
import ContactForm from '../../Sections/ContactForm/ContactForm';

export default {

	init() {

		var container = document.querySelector('main.SingleRealization');

		if( container ) {

			ContactForm.init();

			// -- gallery carousel -- //

				var carouselContainer = container.querySelector('.header__gallery');
				
				if( carouselContainer ) {

					var carousel = carouselContainer.querySelector('.gallery__carousel');
					var buttonNext = carouselContainer.querySelector('.ButtonIcon');

					$(carousel).slick({
						slidesToScroll: 1,
						slidesToShow: 1,
						arrows: false,
						rows: false,
						variableWidth: true,
						autoplay: true,
						autoplaySpeed: 2000,
					});

					buttonNext.addEventListener('click', function() {

						$(carousel).slick('slickNext');

					});

				}

			// -- END -- //

		}

	},
	finalize() {

	}

}