import slick from 'slick-carousel';
import $ from 'jquery';

export default {

	init() {

		var container = document.querySelector('section.PostsSection');

		if( container ) {

			var carousel = container.querySelector('.postsSection__carousel');
			var buttonNext = container.querySelector('.ButtonIcon');

			$(carousel).slick({
				slidesToShow: 3.2,
				slidesToScroll: 1,
				arrows: false,
				infinite: false,
				rows: false,
				responsive: [
					{
						breakpoint: 1221,
						settings : {
							slidesToShow: 2.5,
						}
					},
					{
						breakpoint: 861,
						settings: {
							slidesToShow: 2.2,
						}
					},
					{
						breakpoint: 621,
						settings: {
							slidesToShow: 1.8,
						}
					},
					{
						breakpoint: 481,
						settings: {
							slidesToShow: 1.2,
						}
					}
				]
			});

			buttonNext.addEventListener('click', function() {

				$(carousel).slick('slickNext');

			});

		}

	},
	finalize() {

	}

}