import PS from '../../Components/Photoswipe/Photoswipe';
export default {
	init() {
		var t = this;
		var containers = document.querySelectorAll('.ReferencesSection');
		containers.forEach( function( single,index ) {
			t.finalize( single );
		});

	},
	finalize( container ) {

		var images = container.querySelectorAll('button.grid__single');
		var ps = new PS( container );
		images.forEach( function( image, index ) {
			image.addEventListener('click', function() {
				ps.init( index, images );
			});	
		});
	}	
}