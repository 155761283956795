import LazyLoad from '../../../common/scripts/lazyLoad';
import Subpage from '../../Layout/Subpage';

export default {

	init() {

		var container = document.querySelector('main.ArchiveRealization');

		if( container ) {

			var basicUrl = window.location.origin + window.location.pathname ;
			var maxPosts = parseInt( container.getAttribute('data-max') );

			var xhr = new XMLHttpRequest();
			var xmlDoc = null;

			container.removeAttribute('data-max');

			// -- filter open/close -- //

				function filterOpenClose() {

					var filter = container.querySelector('.archiveRealization__filter');
					var button = filter.querySelector('.filter__button');
					var filters = filter.querySelectorAll('.filter__category');
					// var filterHome = filter.querySelector('.filter__category--light');
					// var filterCompany = filter.querySelector('.filter__category--dark');	

					if( window.innerWidth > 1120 ) {

						let tempWidth = 0;
						filters.forEach( function(single,index) {
							tempWidth += single.offsetWidth;
							single.style.transform = 'translateX(-'+(tempWidth-10)+'px)';
						});

					} else {

						filters.forEach( function( single,index ) {
							single.style.transform = 'translateY('+ ( (index+1) * 10 ) +'px)';
						});

					}

					setTimeout(
						function() {
							filters.forEach( function( single,index ) {
								single.classList.remove('hide');
							});
						},
						100
					);

					button.addEventListener('mouseenter', function() {

						if( window.innerWidth > 1120 ) {

							button.classList.add('active');
							filters.forEach( function( single,index ) {
								single.style.transform = 'translateX(0px)';
							});

						} 

					});	

					button.addEventListener('click', function() {

						if( window.innerWidth <= 1120 ) {

							if( button.classList.contains('active') ) {

								button.classList.remove('active');
								filters.forEach( function( single,index ) {
									single.style.transform = 'translateY('+ ( (index+1) * 10 ) +'px)';
								});

							} else {

								button.classList.add('active');

								let tempHeight = 0;
								filters.forEach( function( single,index ) {
									tempHeight += single.offsetHeight;
									single.style.transform = 'translateY('+(tempHeight)+'px)';
								});

							}

						}

					});

					filter.addEventListener('mouseleave', function() {

						button.classList.remove('active');

						if( window.innerWidth > 1120 ) {

							let tempWidth = 0;
							filters.forEach( function(single,index) {
								tempWidth += single.offsetWidth;
								single.style.transform = 'translateX(-'+(tempWidth-10)+'px)';
							});
							// filterHome.style.transform = 'translateX(-'+(filterHome.offsetWidth-10)+'px)';
							// filterCompany.style.transform = 'translateX(-'+(filterCompany.offsetWidth + filterHome.offsetWidth-10)+'px)';

						} else {

							filters.forEach( function( single,index ) {
								single.style.transform = 'translateY('+ ( (index+1) * 10 ) +'px)';
							});
							// filterHome.style.transform = 'translateY(10px)';
							// filterCompany.style.transform = 'translateY(20px)';

						}

					});

					window.addEventListener('resize', function() {

						if( window.innerWidth > 1120 ) {

							if( button.classList.contains('active') ) {

								filters.forEach( function( single,index ) {
									single.style.transform = 'translateX(0px)';
								});
								// filterHome.style.transform = 'translateX(0px)';
								// filterCompany.style.transform = 'translateX(0px)';

							} else {

								let tempWidth = 0;
								filters.forEach( function(single,index) {
									tempWidth += single.offsetWidth;
									single.style.transform = 'translateX(-'+(tempWidth-10)+'px)';
								});
								// filterHome.style.transform = 'translateX(-'+(filterHome.offsetWidth-10)+'px)';
								// filterCompany.style.transform = 'translateX(-'+(filterCompany.offsetWidth + filterHome.offsetWidth-10)+'px)';

							}

						} else {

							if( button.classList.contains('active') ) {

								let tempHeight = 0;
								filters.forEach( function(single,index) {
									tempWidth += single.offsetHeight;
									single.style.transform = 'translateY(-'+(tempWidth)+'px)';
								});
								// filterHome.style.transform = 'translateY('+(filterHome.offsetHeight)+'px)';
								// filterCompany.style.transform = 'translateY('+(filterCompany.offsetHeight + filterHome.offsetHeight)+'px)';								

							} else {
								
								filters.forEach( function( single,index ) {
									single.style.transform = 'translateY('+ ( (index+1) * 10 ) +'px)';
								});
								// filterHome.style.transform = 'translateY(10px)';
								// filterCompany.style.transform = 'translateY(20px)';	

							}

						}

					});

				}

				filterOpenClose();

			// -- END -- //


			// -- get more realization -- //

				function loadMore() {

					var moreButton;

					document.addEventListener('click', function(e) {

						if( e.target.classList.contains('tiles__more') ) {

							moreButton = document.querySelector('.tiles__more');

							xhr = new XMLHttpRequest();
							xhr.onreadystatechange = function() {

								if( xhr.readyState == 4 && xhr.status == 200) {

			            			var xmlDoc = document.implementation.createHTMLDocument("example");
									xmlDoc.documentElement.innerHTML = xhr.responseText;

									xmlDoc.querySelectorAll('.tiles--size .RealizationTile').forEach( function( box,index ) {

										container.querySelector('.tiles--size').insertBefore(box, container.querySelector('.RealizationTile').lastChild.nextSibling );

									});

									maxPosts = xmlDoc.querySelector('main').getAttribute('data-max');
									container.setAttribute( 'data-offset', container.querySelectorAll('.RealizationTile').length );




									xmlDoc.querySelectorAll('.tiles--size .RealizationMore').forEach( function( box,index ) {

										container.querySelector('.tiles--size').insertBefore(box, container.querySelector('.RealizationMore').lastChild.nextSibling );

									});


									// if( moreButton ) {

									// 	if( container.querySelectorAll('.RealizationTile').length >= maxPosts ) {

									// 		moreButton.classList.add('hide');

									// 	} else {

									// 		moreButton.classList.remove('hide');

									// 	}

									// 	if( container.querySelectorAll('.RealizationTile').length%2 == 1 ) {

									// 		moreButton.classList.add('tiles__more--margin');

									// 	} else {

									// 		moreButton.classList.remove('tiles__more--margin');

									// 	}

									// }

									moreButton.parentNode.remove();

									LazyLoad.init();

									Subpage.slideUp();

			        			} 

							}
							xhr.open( 'GET', basicUrl+'?offset='+container.getAttribute('data-offset')+'&category-id='+container.getAttribute('data-category')+'&ajax=yes', true );
							xhr.send();

						}

					});

				}

				loadMore();

			// -- END -- //


			// -- Load from different category -- //

				function loadFromAnotherCategory() {

					var filterCategories = container.querySelectorAll('.filter__category .row__single');

					var filterButton = container.querySelector('.filter__button');
					// var filterHome = container.querySelector('.filter__category--light');
					// var filterCompany = container.querySelector('.filter__category--dark');	

					var filters = container.querySelectorAll('.filter__category');

					filterCategories.forEach( function( button, index ) {

						button.addEventListener('click', function() {

							filterCategories.forEach( function( button2, index2 ) {

								button2.classList.remove('active');

							});

							button.classList.add('active');

							container.setAttribute('data-category', button.getAttribute('data-id') );

							if( window.innerWidth < 1120 ) {

								filterButton.classList.remove('active');
								filters.forEach( function( filter, index ) {
									filter.style.transform = 'translateY('+ ( (index+1) *10 ) +'px)';
								});
								// filterHome.style.transform = 'translateY(10px)';
								// filterCompany.style.transform = 'translateY(20px)';

							}

							xhr = new XMLHttpRequest();
							xhr.onreadystatechange = function() {

								if( xhr.readyState == 4 && xhr.status == 200) {

			            			var xmlDoc = document.implementation.createHTMLDocument("example");
									xmlDoc.documentElement.innerHTML = xhr.responseText;

									maxPosts = xmlDoc.querySelector('main').getAttribute('data-max');

									container.querySelector('.archiveRealization__tiles').innerHTML = xmlDoc.querySelector('.archiveRealization__tiles').innerHTML;

									LazyLoad.init();

									container.setAttribute('data-offset', container.querySelectorAll('.RealizationTile').length );

									Subpage.slideUp();
										
			        			} 

							}
							xhr.open( 'GET', basicUrl+'?category-id='+button.getAttribute('data-id')+'&ajax=yes', true );
							xhr.send();

						});

					});

				}

				loadFromAnotherCategory();

			// -- END -- //


		}

	},
	finalize() {

	}

}